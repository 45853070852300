@media screen and (max-width: 768px) {
  table {
    border: 0;
    color: #000;
    width: 70%;
    box-shadow: none;
  }

  table thead {
    display: none;
  }

  table tr {
    margin-bottom: 20px;
    display: block;
    border: 2px solid #ddd;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2),
      0 4px 20px 0 rgba(0, 0, 0, 0.19);
  }

  table td {
    display: block;
    height: 20px;
    text-align: right;
    font-size: 13px;
    cursor: pointer;
  }

  table tr td:first-child {
    background-color: hsl(12, 62%, 67%);
  }

  table td:last-child {
    border-bottom: 0;
  }

  table td::before {
    content: attr(data-field-name);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }

  tbody {
    line-height: 0.3 !important;
  }

  /* CLASSES FOR TEAM PAGE LAYOUT */

  .teamsLayout {
    grid-template-rows: auto auto auto 40px 40px auto;
    grid-gap: 10px;
    width: 90%;
    height: auto;
  }

  #scoreboardDiv > img {
    width: 80%;
    height: 60%;
  }

  .gameResults {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr;
    grid-template-rows: repeat(3, 3rem);
    grid-gap: 2px;
    width: 75%;
  }

  .gameHighlights {
    background-color: rgb(21, 87, 83);
    color: white;
    cursor: pointer;
    grid-area: gameHighlights;
    margin: 5px 0px;
  }

  .gameStats {
    background-color: rgb(21, 87, 83);
    color: white;
    cursor: pointer;
    grid-area: gameStats;
    margin: 5px 0px;
  }

  /* END TEAMS PAGE LAYOUT */

  /* records page */

.recordsContainer {
  width: 100%;
}

.recordsNav div {
  background-color: rgb(21,87,83);
  padding: .75em;
  font-size: .75em;
  cursor: pointer;
}

.singleRecordContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
}
