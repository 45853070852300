@media screen and (max-width: 600px) {
  table {
    border: 0;
    width: 90%;
    color: #000;
    box-shadow: none;
  }

  table thead {
    display: none;
  }

  table tr {
    margin-bottom: 20px;
    display: block;
    border: 2px solid #ddd;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2),
      0 4px 20px 0 rgba(0, 0, 0, 0.19);
  }

  table td {
    display: block;
    height: 20px;
    text-align: right;
    font-size: 13px;
    cursor: pointer;
  }

  table tr td:first-child {
    background-color: hsl(12, 62%, 67%);
  }

  table td:last-child {
    border-bottom: 0;
  }

  table td::before {
    content: attr(data-field-name);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }

  tbody {
    line-height: 0.3 !important;
  }

/*navbar*/
/*navbar*/
#teamsNavbar > section {
  gap: .25rem;
}

  /* CLASSES FOR TEAM PAGE LAYOUT */

  .teamsLayout {
    grid-template-rows: auto auto auto 40px 40px auto;
    grid-gap: 10px;
    width: 90%;
    height: auto;
  }

  /* END TEAMS PAGE LAYOUT */

  /* classes for game results*/
  #scoreboardDiv > img {
    width: 100%;
    max-height: 60%;
  }
  .gameResults {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr;
    grid-template-rows: repeat(3, 3rem);
    grid-gap: 2px;
    width: 100%;
    height:auto;
  }
  .gameHighlights {
    background-color: rgb(21, 87, 83);
    color: white;
    cursor: pointer;
    grid-area: gameHighlights;
    margin: 5px 0px;
  }

  .gameStats {
    background-color: rgb(21, 87, 83);
    color: white;
    cursor: pointer;
    grid-area: gameStats;
    margin: 5px 0px;
  }
  /* gifs page */
  .gifsHeaderContainer {
    grid-template-rows: 30px;
  }

  .gifsHomeTeam > img {
    transform: scale(0.70, 0.70);
  }

  .gifsAwayTeam > img {
    transform: scale(0.70, 0.70);
  }

  .gifContainer  > img {
    width: 100%;
  }
  /*end gifs page*/

  /* boxscore page */
  .boxscoreTeamLogosContainer {
    width: 90%;
  }

  .boxscoreHomeTeamLogo {
    margin: auto;
    margin: 0 5%;
  }
  
  .boxscoreAwayTeamLogo {
    margin: auto;
    margin: 0 5%;
  }

  .boxscoreContainer {
    width: 100%;
    margin: auto;
  }

  .boxscoreHomeStats,
  .boxscoreSeperator,
  .boxscoreAwayStats {
    padding: 3px;
  }

  /* end boxscore */
  /* classes for team colors display */

  .teamColorsLayout {
    display: grid;
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 1fr;
    grid-template-areas:
    "colorsBackButton"
      "teamColorsHeader"
      "teamColorsHomeContent"
      "teamColorsAwayContent";
    grid-gap: 5px;
    width: 90%;
    height: auto;
  }

  .colorsBackButton{
    width:fit-content;
    margin: auto;
    grid-area: colorsBackButton;
  }

  .teamColorsHeader {
    text-align: center;
    grid-area: teamColorsHeader;
  }

  .teamColorsHomeContent {
    display: flex;
    justify-content: center;
    grid-area: teamColorsHomeContent;
  }

  .teamColorsAwayContent {
    display: flex;
    justify-content: center;
    grid-area: teamColorsAwayContent;
  }

  .redBlue {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "red blue";
    grid-gap: 2px;
    width: 85%;
    height: auto;
  }
  .redBlue > div:hover {
    cursor: pointer;
  }

  .redBlue > .red {
    grid-area: red;
  }

  .redBlue > .blue {
    grid-area: blue;
  }

/* records page */

.recordsContainer {
  width: 100%;
}

.recordsNav div {
  background-color: rgb(21,87,83);
  padding: 1em;
  font-size: .75em;
  cursor: pointer;
}

.singleRecordContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

}


