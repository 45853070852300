@media screen and (max-width: 992px) {
  #championsCard {
    position: relative;
  }

  table {
    border: 0;
    width: 55%;
    color: #000;
    box-shadow: none;
  }

  table thead {
    display: none;
  }

  table tr {
    margin-bottom: 20px;
    display: block;
    border: 2px solid #ddd;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2),
      0 4px 20px 0 rgba(0, 0, 0, 0.19);
  }

  table td {
    display: block;
    height: 20px;
    text-align: right;
    font-size: 13px;
    cursor: pointer;
  }

  table td:nth-child(odd) {
    background-color: #fff;
  }
  table td:nth-child(even) {
    background-color: #f0f0f0;
  }

  table tr td:first-child {
    background-color: hsl(12, 62%, 67%);
  }

  table td:last-child {
    border-bottom: 0;
  }

  table td::before {
    content: attr(data-field-name);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
  }

  tbody {
    line-height: 0.3 !important;
  }

  .gameResults {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr;
    grid-template-rows: repeat(3, 3rem);
    grid-gap: 2px;
    width: 75%;
  }

  .gameHighlights {
    background-color: rgb(21, 87, 83);
    color: white;
    cursor: pointer;
    grid-area: gameHighlights;
    margin: 5px 0px;
  }

  .gameStats {
    background-color: rgb(21, 87, 83);
    color: white;
    cursor: pointer;
    grid-area: gameStats;
    margin: 5px 0px;
  }
}
