@media screen and (max-width: 1200px) {
  #championsCard {
    position: relative;
  }

  table {
    font-size: 1.1em;
    width: 60%;
  }

  .gifContainer  > img {
    width: 75%;
  }
}
