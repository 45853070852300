*,
::after,
::before {
  box-sizing: border-box;
}

body {
  background-color: #2196f3;
}

header {
  position: relative;
  margin-top: 5px;
}

/* header image */
img[src*="-big-"] {
  cursor: pointer;
}

.trophy {
  color: yellow;
  font-size: 2em;
}

#openSidebar {
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  margin: 10px;
  overflow: visible;
}

#sidebar {
  left: 0vh;
  max-height: 100vh;
  overflow-y: auto;
}

#championsCard {
  position: absolute;
  top: 0px;
  right: 0px;
}

#tablesDiv {
  container-type: inline-size;
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 50%;
  border: 1px solid #ccc;
  color: black;
  font-size: 1.2em;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}

table th:hover {
  cursor: pointer;
  background-color: #ffeb3b;
}

table tr {
  border-bottom: 1px solid #ddd;
}

table tr:nth-child(odd) {
  background-color: #fff;
}
table tr:nth-child(even) {
  background-color: #f0f0f0;
}

table td,
table th {
  padding: 8px 8px;
  text-align: center;
  vertical-align: top;
}

table th:first-child,
table td:first-child {
  padding-left: 16px;
}

/*navbar*/
#teamsNavbar > section {
  padding-top: 1rem;
  padding-bottom: 1rem;
  min-height: auto;
  gap: 1rem;
}

.navLogoContainer {
  padding: .5rem;
}

.three-d-Logo {
  width: clamp(2rem, 5vw, 6.25rem);
  height: clamp(2rem, 5vw, 6.25rem);
  clip-path: circle(); 
}

.navLogo:hover {
  cursor: pointer;
}

/*classes for teams page layout*/

.teamsLayout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto 50px 50px auto;
  grid-template-areas:
    "teamName teamName"
    "gameType gameType"
    "teamRecord teamRecord"
    "players teamColors"
    "seasonScoreboard playoffScoreboard"
    "notes notes";
  grid-gap: 10px;
  width: 50%;
}

.teamName {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: teamName;
}

.gameType {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: gameType;
}

.teamRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: teamRecord;
}

.players {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  grid-area: players;
}

.teamColors {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  grid-area: teamColors;
}

.seasonScoreboard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(238, 125, 32);
  color: white;
  cursor: pointer;
  grid-area: seasonScoreboard;
}

.playoffScoreboard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 150, 243);
  color: white;
  cursor: pointer;
  grid-area: playoffScoreboard;
}

.notes {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: notes;
}

/*end teams page layout*/

/* classes for game results display */

#scoreboardDiv > img {
  width: min(60%, 700px);
  max-height: 450px;
  object-fit: scale-down;
}

.gameResults {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  grid-template-rows: repeat(3, 50px);
  grid-template-areas:
    "homeTeamLogo homeTeam homeScore gameResultsBox"
    "awayTeamLogo awayTeam awayScore gameResultsBox"
    "gameHighlights gameHighlights gameStats gameStats";
  grid-gap: 2px;
  width: 50%;
}

.homeTeamLogo,
.homeTeam,
.awayTeamLogo,
.awayTeam,
.homeScore,
.awayScore,
.gameResultsBox,
.gameHighlights,
.gameStats {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeTeamLogo {
  background-color: rgb(102, 121, 206);
  grid-area: homeTeamLogo;
}

.homeTeam {
  grid-area: homeTeam;
}

.awayTeamLogo {
  background-color: rgb(102, 121, 206);
  grid-area: awayTeamLogo;
}

.awayTeam {
  grid-area: awayTeam;
}

.homeScore {
  grid-area: homeScore;
}

.awayScore {
  grid-area: awayScore;
}

.gameResultsBox {
  grid-area: gameResultsBox;
}

.gameHighlights {
  background-color: rgb(21, 87, 83);
  color: white;
  cursor: pointer;
  grid-area: gameHighlights;
}

.gameStats {
  background-color: rgb(21, 87, 83);
  color: white;
  cursor: pointer;
  grid-area: gameStats;
}

/*end game results layout*/

/* gifs page */
.gifsHeaderContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto auto;
  align-items: center;
  grid-template-areas: "gifsBackButton gifsBackButton gifsBackButton gifsBackButton gifsBackButton"
                        "gifsHomeTeam gifsHomeTeamScore gifsVsHeader gifsAwayTeamScore gifsAwayTeam";
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 25px;
}

.gifsBackButton{
  grid-area: gifsBackButton;
  width:fit-content;
  margin: auto;
  margin-bottom: 25px;
}

.gifsHomeTeam {
  grid-area: gifsHomeTeam;
}
.gifsHomeTeamScore {
  grid-area: gifsHomeTeamScore;
  font-size: 2em;
  overflow: hidden;
}
.gifsVsHeader {
  grid-area: gifsVsHeader;
  font-size: 1.5em;
  overflow: hidden;
}
.gifsAwayTeamScore {
  grid-area: gifsAwayTeamScore;
  font-size: 2em;
  overflow: hidden;
}
.gifsAwayTeam {
  grid-area: gifsAwayTeam;
}

.gifContainer  > img {
  width: 65%;
}

/*end gifs page*/

/* classes for boxscore page*/

/* boxscore header (team logos) */
.boxscoreTeamLogosContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  align-items: center;
  grid-template-areas: "boxscoreBackButton boxscoreBackButton "
                        "boxscoreHomeTeamLogo boxscoreAwayTeamLogo "
  ;
  overflow: hidden;
  width: 75%;
}

.boxscoreBackButton{
  grid-area: boxscoreBackButton;
  width:fit-content;
  margin: auto;
  margin-bottom: 25px;
}

.boxscoreHomeTeamLogo {
  grid-area: boxscoreHomeTeamLogo;
  width:fit-content;
  margin: auto;
  margin-left: 42%;
}

.boxscoreAwayTeamLogo {
  grid-area: boxscoreAwayTeamLogo;
  width: fit-content;
  margin: auto;
  margin-right: 42%;
}
/* end boxscore header */

/*  boxscore stats */
.boxscoreContainer {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 50%;
  margin: auto;
}

.boxscoreHomeStats,
.boxscoreSeperator,
.boxscoreAwayStats {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(21, 87, 83);
  color: white;
  width: 33%;
  margin: 1px;
  padding: 5px;
}

/* boxscore players table*/

#boxscorePlayerStats table {
  margin: auto;
}

/* end boxscore page*/

/* classes for team colors display */

.teamColorsLayout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
  "colorsBackButton colorsBackButton"
    "teamColorsHeader teamColorsHeader"
    "teamColorsHomeContent teamColorsAwayContent";
  grid-gap: 5px;
  width: 75%;
  height: auto;
}

.colorsBackButton{
  width:fit-content;
  margin: auto;
  grid-area: colorsBackButton;
}

.teamColorsHeader {
  text-align: center;
  grid-area: teamColorsHeader;
}

.teamColorsHomeContent {
  display: flex;
  justify-content: center;
  grid-area: teamColorsHomeContent;
}

.teamColorsAwayContent {
  display: flex;
  justify-content: center;
  grid-area: teamColorsAwayContent;
}

.redBlue {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "red blue";
  grid-gap: 2px;
  width: 85%;
  height: auto;
}
.redBlue > div:hover {
  cursor: pointer;
}

.redBlue > .red {
  grid-area: red;
}

.redBlue > .blue {
  grid-area: blue;
}

/* records page */

.recordsContainer {
  width: 75%;
}

.recordsNav {
  display:flex;
  justify-content: space-between;
}

.recordsNav div {
  background-color: rgb(21,87,83);
  padding: 1em;
  font-size: 1.25em;
  cursor: pointer;
}

.singleRecordContainer {
  display: flex;
  justify-content: space-around;
}

.singleRecordContainer div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(21,87,83);
}

.recordPlayers {
  flex-direction: column;
}


