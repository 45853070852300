/* entire playoff tree container*/
/* can look different depending on playoff format for any given season */

/* playoffs that have 3 teams (season 2) */
.playoffTreeThreeTeams {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 7rem 3rem repeat(4, 1fr);
    grid-template-areas: 
        "playoffAnnouncement playoffAnnouncement"
        "firstRound ."
        "byeContainer finalRound"
        "byeContainer finalsContainer"
        "semi2Container finalsContainer"
        "semi2Container ."
    ;
    margin: 1rem 0;
    width: 100%;
    max-width: 900px;
    color: white;
}

/* playoffs that have four teams */
.playoffTreeFourTeams {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 7rem 3rem repeat(4, 1fr);
    grid-template-areas: 
        "playoffAnnouncement playoffAnnouncement"
        "firstRound ."
        "semi1Container finalRound"
        "semi1Container finalsContainer"
        "semi2Container finalsContainer"
        "semi2Container ."
    ;
    margin: 1rem 0;
    width: 100%;
    max-width: 900px;
    color: white;
}

/* playoffs that have four teams */
.playoffTreeFiveTeams {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 7rem 3rem repeat(6, 1fr);
    grid-template-areas: 
        "playoffAnnouncement playoffAnnouncement playoffAnnouncement"
        "firstRound secondRound ."
        "byeContainer secondRoundDiv1Container ."
        "byeContainer secondRoundDiv1Container finalRound"
        "semi1Container secondRoundDiv2Container finalsContainer"
        "semi1Container secondRoundDiv2Container finalsContainer"
        "semi2Container secondRoundDiv3Container ."
        "semi2Container secondRoundDiv3Container ."
    ;
    margin: 1rem 0;
    width: 100%;
    max-width: 1200px;
    color: white;
}

/* playoff tree inner containers that hold individual containers for each series */
.playoffAnnouncement {
    grid-area: playoffAnnouncement;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    background-color: hsl(from #2196f3 h s 43%);
    border-radius: .5rem;
}

.byeContainer {
    grid-area: byeContainer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.firstRound {
    grid-area: firstRound;
    justify-content: center;
    align-items: center;
}

.secondRound {
    grid-area: secondRound;
    justify-content: center;
    align-items: center;
}

.finalRound {
    grid-area: finalRound;
    display: flex;
    justify-content: center;
    align-items: end;
    color: black;
}

:is(.firstRound, .secondRound){
    display: flex;
    color: black;
    margin-bottom: 1rem;
}

.semi1Container {
    grid-area: semi1Container;
    margin-bottom: 1rem;
}

.semi2Container {
    grid-area: semi2Container;
    margin-bottom: 1rem;
}

.semi3Container {
    grid-area: semi3Container;
}

.secondRoundDiv1Container {
    grid-area: secondRoundDiv1Container;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.secondRoundDiv2Container {
    grid-area: secondRoundDiv2Container;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.secondRoundDiv3Container {
    grid-area: secondRoundDiv3Container;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.finalsContainer {
    grid-area: finalsContainer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

:is(.byeContainer, .semi1Container, .semi2Container, .semi3Container, .secondRoundDiv1Container, .secondRoundDiv2Container, .secondRoundDiv3Container,.finalsContainer){
    background-color: hsl(from #2196f3 h s 43%);
    border-radius: .5rem;
}

:is(.byeContainer, .semi1Container, .semi2Container, .semi3Container, .secondRoundDiv1Container, .secondRoundDiv2Container, .secondRoundDiv3Container,){
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}

/* inner series containers */

:is(.semi1, .semi2, .semi3){
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
}

/* inner series grids */

.series{
    display: flex;
    flex-direction: column;
    gap:1rem;
    color: white;
}

:is(.team1, .team2){
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

:is(.team1 .teamData:first-child, .team2 .teamData:first-child){
    flex-grow: 1;
    width: 100%;
    min-width: 15rem;
}

:is(.teamData) {
    padding: 1vw;
}

@container (max-width: 1000px) {
    /* four teams grid */
    .playoffTreeFiveTeams {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 7rem 3rem 1fr 1fr 1fr 3rem 1fr 1fr 1fr 3rem 1fr;
        grid-template-areas: 
            "playoffAnnouncement playoffAnnouncement"
            "firstRound firstRound"
            "byeContainer byeContainer"
            "semi1Container semi1Container"
            "semi2Container semi2Container"
            "secondRound secondRound"
            "secondRoundDiv1Container secondRoundDiv1Container"
            "secondRoundDiv2Container secondRoundDiv2Container"
            "secondRoundDiv3Container secondRoundDiv3Container"
            "finalRound finalRound"
            "finalsContainer finalsContainer"
        ;
        width: 75%;
    }
}

@container (max-width: 695px) {
    /* three teams grid */
    .playoffTreeThreeTeams {
        grid-template-rows: 7rem 3rem 1fr 1fr 3rem 1fr;
        grid-template-areas: 
            "playoffAnnouncement playoffAnnouncement"
            "firstRound firstRound"
            "byeContainer byeContainer"
            "semi2Container semi2Container"
            "finalRound finalRound"
            "finalsContainer finalsContainer"
        ;
    }
    /* four teams grid */
    .playoffTreeFourTeams {
        grid-template-rows: 7rem 3rem 1fr 1fr 3rem 1fr;
        grid-template-areas: 
            "playoffAnnouncement playoffAnnouncement"
            "firstRound firstRound"
            "semi1Container semi1Container"
            "semi2Container semi2Container"
            "finalRound finalRound"
            "finalsContainer finalsContainer"
        ;
    }

    .playoffTreeFiveTeams {
        width: 100%;
    }

    :is(.team1 .teamData:first-child, .team2 .teamData:first-child){
        min-width: 12rem;
    }

    :is(.secondRoundDiv1Container, .secondRoundDiv2Container, .secondRoundDiv3Container, .finalsContainer){
        margin-left: 0;
    }
}